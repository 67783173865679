import { Dialog as MuiDialog, DialogActions, DialogContent } from "@material-ui/core"
import { Box, Button, Typography } from "components"
import { compose, withHooks } from "enhancers"
import styled from "styled-components"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: auto;
  }
`

const ModalContainer = styled(DialogContent)`
  min-width: 800px;
  height: 100%;
  padding: 16px;
`

const IframeContainer = styled(Box)`
  margin-top: 16px;
  width: 100%;
  height: 1000px;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

interface MarketplaceReportModalProps {
  isOpen: boolean
  reportUrl: string
  onClose: () => void
  title?: string
}

const MarketplaceReportModalComponent = (props: MarketplaceReportModalProps) => (
  <Dialog open={props.isOpen}>
    <ModalContainer>
      <Typography variant="h3">{props.title || "รายงาน"}</Typography>

      {props.reportUrl && (
        <IframeContainer>
          <iframe src={props.reportUrl} width="100%" height="100%" title="Report Preview" style={{ border: "none" }} />
        </IframeContainer>
      )}
    </ModalContainer>
    <DialogActions>
      <ActionContainer>
        <ActionButton variant="outlined" onClick={props.onClose}>
          ปิดหน้าต่างนี้
        </ActionButton>
      </ActionContainer>
    </DialogActions>
  </Dialog>
)

const enhancer = compose(
  withHooks((props: MarketplaceReportModalProps) => {
    return {
      ...props,
    }
  }),
)

export const MarketplaceReportModal = enhancer(MarketplaceReportModalComponent)
