interface ClaimIdDisplayProps {
  claimId: string
}

export const ClaimIdDisplay = ({ claimId }: ClaimIdDisplayProps) => {
  const isExpPrefix = claimId?.startsWith("EXP-")

  if (isExpPrefix) {
    return <>{claimId}</>
  }

  return (
    <>
      {claimId}
      <b>&nbsp;(กรุณารอเลขอ้างอิง 12 หลัก สำหรับเขียนเอกสารส่งบัญชีหลังจาก HR อนุมัติ ตย. EXP-011024006291)</b>
    </>
  )
}
